<template>
  <div id="EditUser">
    <b-row>
      <b-col sm="8">
        <div class="card">
          <div slot="header" class="card-header">
            <i class="fa fa-edit"></i>
            <span v-if="$route.params.id == '-1'">Benutzer anlegen</span>
            <span v-else-if="changePw">Passwort ändern</span>
            <span v-else>Benutzer bearbeiten</span>
            <button v-if="$route.params.id != '-1' & !changePw" @click="changePassword()" class="btn btn-primary btn-sm"
                    style="float:right"><span class="icon-lock"></span> Passwort ändern
            </button>
          </div>
          <div v-if="user" class="card-body">
            <b-form v-on:submit.prevent="onSubmit">
              <b-form-group validated>
                <label for="name">Benutzername (Email-Adresse)</label>
                <b-form-input type="email" id="name" :disabled="$route.params.id != -1" required aria-required="true"
                              v-model="user.username" placeholder="Email-Adresse"></b-form-input>
              </b-form-group>
              <b-row v-if="changePw == false">
                <b-col md="6">
                  <b-form-group>
                    <label for="firstname">Vorname</label>
                    <b-form-input type="text" id="firstname" v-model="user.firstname"
                                  placeholder="Vorname"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="lastname">Nachname</label>
                    <b-form-input type="text" id="lastname" v-model="user.lastname"
                                  placeholder="Nachname"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group validated v-if="$route.params.id == '-1' || changePw">
                <b-row>
                  <b-col md="6">
                    <label for="password">Passwort</label>
                    <b-input-group>
                      <b-form-input type="password" id="password" required :aria-required="true" v-model="user.password"
                                    placeholder="Passwort"></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="6">
                    <label for="passwordrepeat">Passwort-Wiederholung</label>
                    <b-input-group>
                      <b-form-input type="password" id="passwordrepeat" v-model="pwRepeat" required
                                    :aria-required="true" placeholder="Passwort-Wiederholung"></b-form-input>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-row>
                <b-col>
                  <label>Rolle</label>
                  <b-form-select v-model="user.role" :options="roleOptions" value="-1"></b-form-select>
                </b-col>
              </b-row>
              <br/>
              <b-row :class='user.role ==1?"hidden":""'>
                <b-col>
                  <label>Medien-Ordner</label>
                  <v-select v-model="folderSelection" multiple :close-on-select="false" :options="folderOptions"
                            @input="onFolderSelect({ value: $event })"
                            placeholder="Bitte wählen Sie die Medien-Ordner auf die der Benutzer Zugriff haben soll"></v-select>
                </b-col>
              </b-row>

              <br>
              <div class="footer-btn" slot="footer" style="margin-top: 10px;">
                <b-button type="submit" stacked size="sm" variant="primary"><i class="fa fa-save"></i> Speichern
                </b-button>&nbsp;
                <b-button size="sm" href="/#/user/users/" variant="danger"><i class="fa fa-ban"></i> Abbrechen
                </b-button>
              </div>
            </b-form>
          </div>
          <br>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

  import {mapGetters, mapState} from 'vuex'
  import vSelect from 'vue-select'
  import VueNotifications from 'vue-notifications'
  import {Switch as cSwitch} from '@coreui/vue'

  const axios = require('axios')

  export default {
    components: {
      cSwitch,
      vSelect,
    },
    name: 'editUser',
    data() {
      return {
        pwRepeat: '',
        changePw: false,
        roleOptions: [{value: 1, text: 'Administrator'}, {value: null, text: "Standardbenutzer"}],
        folderSelection: []
      }
    },
    computed: {
      ...mapState({
        folderOptions: state => state.media.folderOptions
      }),
      ...mapGetters({
        user: 'user/getUser',
        error: 'user/getError'
      })
    },
    mounted() {
      if (this.$route.params.id != '-1') {
        this.$store.dispatch('user/loadUser', this.$route.params.id)
      } else {
        this.$store.dispatch('user/initUser')
      }

      if (this.$route.query.changePw == 'true') {
        this.changePw = true
      }
    },
    methods: {
      onSubmit() {
        if (this.$route.params.id == -1) {
          if (this.pwRepeat != this.user.password) {
            this.notifyPasswordError()
            return;
          }
        }

        this.$store.dispatch('user/saveUser', {id: this.$route.params.id}).then(
          response => {
            if (response.status == 200 || response.status == 201) {
              this.$router.replace(this.$route.query.redirect || '/user')
            }
          }, error => {
            if (error.response.status == 409) {
              this.notifyDuplicateUserError()
            } else {
              this.notifySaveError()
            }
          }
        )
      },
      changePassword() {
        this.changePw = true
      },
      onFolderSelect() {
        this.user.mediaFolders = []
        for (let i = 0; i < this.folderSelection.length; i++) {
          this.user.mediaFolders.push(this.folderSelection[i].value)
        }
      }
    },

    watch: {
      user: function () {
        this.$store.dispatch('media/initFolderOptions').then(
          response => {
            for (let i = 0; i < this.$store.state.user.user.mediaFolders.length; i++) {
              for (let j = 0; j < this.folderOptions.length; j++) {
                if (this.$store.state.user.user.mediaFolders[i] == this.folderOptions[j].value) {
                  this.folderSelection.push({
                    'value': this.folderOptions[j].value,
                    'label': this.folderOptions[j].label
                  })
                  break;
                }
              }
            }
          }
        )
      }
    },

    notifications: {
      notifySaveSuccess: {
        type: VueNotifications.types.success,
        title: 'Benutzer wurde erfolgreich gespeichert',
      },
      notifySaveError: {
        type: VueNotifications.types.error,
        title: 'Fehler beim speichern des Benutzers'
      },
      notifyPasswordError: {
        type: VueNotifications.types.error,
        title: 'Die beiden eingegebenen Passwörter stimmen nicht überein!'
      },
      notifyDuplicateUserError: {
        type: VueNotifications.types.error,
        title: 'Ein Benutzer mit dieser Email-Adresse existiert bereits im System'
      }
    }
  }
</script>
